// import React from 'react';

// function Footer() {
//   return (
//     <footer>
//       <p>&copy; 2024 AstechServices. All Rights Reserved.</p>
//       <div className="social-links">
//         <a href="#">LinkedIn</a> | <a href="#">Twitter</a> | <a href="#">Facebook</a>
//       </div>
//     </footer>
//   );
// }

// export default Footer;



import React from 'react';
import './Footer.css';
import BackgroundImage from '../../assets/Background1.jpeg';

function Footer() {
  return (
    <footer className="footer" style={{ backgroundImage: `url(${BackgroundImage})` }}>
      <p>&copy; 2024 AstechServices. All Rights Reserved.</p>
      <div className="social-links">
        <a href="#">LinkedIn</a> | <a href="#">Twitter</a> | <a href="#">Facebook</a>
      </div>
    </footer>
  );
}

export default Footer;
