import React, { useState } from 'react';
import './Services.css';

const servicesData = [
  { title: "Custom Software Development", description: "Tailored software solutions to meet specific business needs." },
  { title: "Web Development", description: "Design and development of responsive and user-friendly websites." },
  { title: "Mobile App Development", description: "Creation of mobile applications for iOS and Android platforms." },
  { title: "Cloud Solutions", description: "Cloud infrastructure setup and management (AWS, Azure)." },
  { title: "Cybersecurity Services", description: "Comprehensive security audits, penetration testing, and compliance solutions." },
  { title: "IT Consulting", description: "Strategic advice and solutions for IT infrastructure and processes." },
  { title: "Digital Marketing", description: "SEO, social media management, content marketing, and PPC advertising." },
  { title: "Data Analytics and Business Intelligence", description: "Data analysis, visualization, and reporting solutions." },
  { title: "ERP Solutions", description: "Implementation and customization of enterprise resource planning systems." },
  { title: "E-commerce Solutions", description: "Development of online stores and payment gateway integration." },
  { title: "Technical Support and Maintenance", description: "Ongoing support and maintenance for software and IT systems." },
];

const Service = ({ title, description }) => (
  <div className="service">
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

function Services() {
  const [startIndex, setStartIndex] = useState(0);
  const servicesToShow = 4;

  const handleNext = () => {
    setStartIndex((prevIndex) => Math.min(prevIndex + servicesToShow, servicesData.length - servicesToShow));
  };

  const handlePrev = () => {
    setStartIndex((prevIndex) => Math.max(prevIndex - servicesToShow, 0));
  };

  return (
    <section className="services">
      <h2>Our Services</h2>
      <div className="service-navigation">
        <button className="nav-button" onClick={handlePrev} disabled={startIndex === 0}>◀</button>
        
        <div className="service-list">
          {servicesData.slice(startIndex, startIndex + servicesToShow).map((service, index) => (
            <Service key={index} title={service.title} description={service.description} />
          ))}
        </div>
        
        <button className="nav-button" onClick={handleNext} disabled={startIndex + servicesToShow >= servicesData.length}>▶</button>
      </div>
    </section>
  );
}

export default Services;
