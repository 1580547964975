import React, { useState } from 'react';
import './Navbar.css';
import logo from '../../assets/logo.jpeg';
import logo1 from '../../assets/logo1.jpeg';

function Navbar() {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img
          src={hovered ? logo1 : logo}
          alt="Company Logo"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="logo-image"
        />
      </div>
      <ul className="navbar-links">
        <li><a href="#home">Home</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>
    </nav>
  );
}

export default Navbar;
