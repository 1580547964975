import React, { useState } from 'react';
import './CompanyOverview.css';
import companyImage from '../../assets/company-image1.png';
import companyImageHover from '../../assets/company-image.png';
import { FaCloud, FaMobileAlt, FaCode, FaChartLine } from 'react-icons/fa';

const CompanyOverview = () => {
  const [currentImage, setCurrentImage] = useState(companyImage);

  const handleMouseEnter = () => {
    setCurrentImage(companyImageHover);
  };

  const handleMouseLeave = () => {
    setCurrentImage(companyImage);
  };

  return (
    <section className="company-overview">
      <div className="container">
        <h2 className="title">Transforming Ideas into Digital Excellence</h2>
        
        <div className="overview-content">
          <img
            src={currentImage}
            alt="Company"
            className="company-image"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <div className="text-content">
            <p className="overview-text">
              Astech Services is a pioneering technology firm dedicated to crafting bespoke software solutions that propel business growth. Our expertise spans custom software development, cloud integration, AI, and comprehensive full-stack web and mobile applications.
            </p>
            <p className="overview-text">
              With a skilled team proficient in React, AWS, Python, and Spring Boot, we harness cutting-edge technologies to deliver solutions that not only meet but exceed our clients' aspirations. At Astech, innovation is at our core; we continuously adapt to emerging trends to empower businesses. Our commitment to quality and collaboration ensures each project is a step towards success.
            </p>
            
            <div className="icon-section">
              <div className="icon-card">
                <FaCloud className="icon" />
                <p>Cloud Integration</p>
              </div>
              <div className="icon-card">
                <FaMobileAlt className="icon" />
                <p>Mobile Applications</p>
              </div>
              <div className="icon-card">
                <FaCode className="icon" />
                <p>Custom Development</p>
              </div>
              <div className="icon-card">
                <FaChartLine className="icon" />
                <p>Business Analytics</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyOverview;
