import React from 'react';
import './Legal.css';

function Legal() {
  return (
    <section className="legal">
      <h2>Privacy Policy & Terms of Service</h2>
      <p>
        Read our <a href="#">Privacy Policy</a> and <a href="#">Terms of Service</a> to learn how we protect your data and ensure a safe online experience.
      </p>
    </section>
  );
}

export default Legal;
