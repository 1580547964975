import React, { useRef } from 'react';
import './App.css';
import Navbar from './Components/Navbar/Navbar'; // Import the Navbar component
import Hero from './Components/Hero/Hero';
import CompanyOverview from './Components/Company Overview/CompanyOverview';
import Careers from './Components/Careers/Careers';
import Services from './Components/Services/Services';
// import Blog from './Components/Blog/Blog';
import ContactUs from './Components/Contact Us/ContactUs';
import FAQ from './Components/FAQ/FAQ';
import Legal from './Components/Legal/Legal';
import Footer from './Components/Footer/Footer';

function App() {
  const contactUsRef = useRef(null);

  const handleGetStartedClick = () => {
    contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="App">
      <Navbar /> {/* Navbar added here */}
      <Hero onGetStartedClick={handleGetStartedClick} />
      <CompanyOverview />
      <Careers />
      <Services />
      {/* <Blog /> */}
      
      {/* Contact Us section with ref to enable smooth scrolling */}
      <div ref={contactUsRef}>
        <ContactUs />
      </div>

      <FAQ />
      <Legal />
      <Footer />
    </div>
  );
}

export default App;
