import React from 'react';
import './ContactUs.css';

function ContactUs() {
  return (
    <section className="contact-us">
      <div className="contact-container">
        <div className="contact-form">
          <h2>Contact Us</h2>
          <form>
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" placeholder="Your name" required />

            <label htmlFor="email">Email:</label>
            <input type="email" id="email" placeholder="Your email" required />

            <label htmlFor="message">Message:</label>
            <textarea id="message" placeholder="Your message" required></textarea>

            <button type="submit">Send</button>
          </form>
        </div>

        <div className="contact-info">
          <h2>Get In Touch</h2>
          <p>
            <strong>Email:</strong> 
            <a href="mailto:astechservices.in@gmail.com">astechservices.in@gmail.com</a>
          </p>
          <p>
            <strong>Phone:</strong> 
            <a href="tel:+918390828319">+91 83908 28319</a>
          </p>
          <p>
            <strong>Location:</strong> Ravet, Pune, Maharashtra
          </p>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
