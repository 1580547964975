import React, { useState } from 'react';
import { FaPhoneAlt, FaEnvelope, FaTimes } from 'react-icons/fa';
import './Hero.css';
import BackgroundImage from '../../assets/Background1.jpeg';

function Hero({ onGetStartedClick }) {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handlePopupOpen = () => {
    setIsPopupVisible(true);
  };

  const handlePopupClose = () => {
    setIsPopupVisible(false);
  };

  return (
    <section className="hero" style={{ backgroundImage: `url(${BackgroundImage})` }}>
      <h1>AstechServices</h1>
      <h2>Empowering Your Business with Innovative Tech Solutions</h2>
      <p>Your success is our priority. Let’s grow together.</p>
      
      <div className="hero-buttons">
        <button className="primary-button" onClick={onGetStartedClick}>
          Get Started
        </button>
        <button className="secondary-button" onClick={handlePopupOpen}>
          Contact Us
        </button>
      </div>

      {isPopupVisible && (
        <div className="popup-overlay" onClick={handlePopupClose}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={handlePopupClose}>
              <FaTimes />
            </button>
            <h3>Contact Information</h3>
            <div className="contact-details">
              <a href="tel:+15551234567" className="contact-item">
                <FaPhoneAlt className="contact-icon" /> +918390828319
              </a>
              <a href="mailto:astechservices.in@gmail.com" className="contact-item">
                <FaEnvelope className="contact-icon" /> astechservices.in@gmail.com
              </a>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default Hero;
