import React from 'react';
import './FAQ.css';
import faqImage from '../../assets/FAQ1.jpeg'; // Adjusted image path

const FAQItem = ({ question, answer }) => (
  <div className="faq-item">
    <h3>{question}</h3>
    <p>{answer}</p>
  </div>
);

function FAQ() {
  return (
    <section className="faq-container">
      <div className="faq-image">
        <img src={faqImage} alt="FAQ" />
      </div>
      <div className="faq">
        <h2>Frequently Asked Questions</h2>
        <FAQItem 
          question="What services do you offer?" 
          answer="We offer a wide range of tech solutions, including software development, cloud services, and more." 
        />
        <FAQItem 
          question="How do I get started?" 
          answer="You can contact us through our form, or simply give us a call." 
        />
        <FAQItem 
          question="What technologies do you specialize in?" 
          answer="Our team specializes in Java, React, Node.js, and various cloud platforms like AWS." 
        />
        <FAQItem 
          question="What is your approach to software development?" 
          answer="We follow Agile methodologies to ensure flexibility and collaboration throughout the project lifecycle." 
        />
        <FAQItem 
          question="Do you offer post-launch support?" 
          answer="Absolutely! We provide ongoing support and maintenance after your project goes live." 
        />
      </div>
    </section>
  );
}

export default FAQ;
